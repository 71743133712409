import React from 'react';
import { Button, Input } from 'reactstrap';
import SwitchExamples from 'containers/forms/SwitchExamples';

import TSTLogo from '../assets/logos/blue_logo.svg';
import { mappedStatus } from './function';

export const urlList = {
  // Login API
  login: { type: 'POST', endpoint: '/admins/login' },
  getAdmin: { type: 'GET', endpoint: '/admins/me' },

  // Dashboard API
  getAnalytics: { type: 'GET', endpoint: '/admin/analysis' },

  // Society APIs
  getSocieties: { type: 'GET', endpoint: '/admin/society' },
  addSocieties: { type: 'POST', endpoint: '/admin/society' },
  editSocieties: { type: 'PATCH', endpoint: '/admin/society/:id' },
  deleteSocieties: { type: 'DELETE', endpoint: '/admin/society/:id' },

  // Society -> Tower API
  getTower: { type: 'GET', endpoint: '/admin/tower' },
  addTower: { type: 'POST', endpoint: '/admin/tower' },
  deleteTower: { type: 'DELETE', endpoint: '/admin/tower/:id' },
  editTower: { type: 'PATCH', endpoint: '/admin/tower/:id' },

  // Society -> Flat API
  getFlat: { type: 'GET', endpoint: '/admin/room' },
  addFlat: { type: 'POST', endpoint: '/admin/room' },
  editFlat: { type: 'PATCH', endpoint: '/admin/room/:id' },
  deleteFlat: { type: 'DELETE', endpoint: '/admin/room/:id' },

  // Society -> Service API
  getSocietyServices: { type: 'GET', endpoint: '/admin/societyService' },
  addSocietyServices: { type: 'POST', endpoint: '/admin/societyService' },
  deleteSocietyServices: {
    type: 'DELETE',
    endpoint: '/admin/societyService/:id',
  },
  editSocietyServices: {
    type: 'PATCH',
    endpoint: '/admin/societyService/:id',
  },

  // Society -> Parking API
  getParking: { type: 'GET', endpoint: '/admin/parking' },
  addParking: { type: 'POST', endpoint: '/admin/parking' },
  deleteParking: { type: 'DELETE', endpoint: '/admin/parking/:id' },
  editParking: { type: 'PATCH', endpoint: '/admin/parking/:id' },

  // Society -> User API
  getSocietyUsers: { type: 'GET', endpoint: '/admin/userAddress' },
  editSocietyUsers: { type: 'PATCH', endpoint: '/admin/userAddress' },

  // Service API
  getServices: { type: 'GET', endpoint: '/admin/service' },
  getOneServices: { type: 'GET', endpoint: '/admin/service/:id' },
  addService: { type: 'POST', endpoint: '/admin/service' },
  deleteService: { type: 'DELETE', endpoint: '/admin/service/:id' },
  editService: { type: 'PATCH', endpoint: '/admin/service/:id' },

  // Service -> Service Varity API
  getServiceVariety: { type: 'GET', endpoint: '/admin/variety' },
  deleteServiceVariety: { type: 'DELETE', endpoint: '/admin/variety/:id' },
  editServiceVarietyOption: { type: 'PATCH', endpoint: '/admin/variety/:id' },

  // Users API
  getUsers: { type: 'GET', endpoint: '/admin/users' },
  getOneUser: { type: 'GET', endpoint: '/admin/users/:id' },
  deleteUser: { type: 'DELETE', endpoint: '/admin/users/:id' },
  editUser: { type: 'PATCH', endpoint: '/admin/users/:id' },

  // User -> Details API
  getUserDetail: { type: 'GET', endpoint: '/admin/userAddress' },

  // User -> Order service API
  getOrderUserData: { type: 'GET', endpoint: '/admin/order' },

  // User -> Booking service API
  getUserData: { type: 'GET', endpoint: '/admin/booking' },

  // User -> Transaction API
  getUserTransactionData: { type: 'GET', endpoint: '/admin/transaction' },

  // User -> raised Ticket API
  getUserTicket: { type: 'GET', endpoint: '/admin/reportTable' },
  addUserTicket: { type: 'POST', endpoint: '/admin/reportTable' },
  editUserTicket: { type: 'PATCH', endpoint: '/admin/reportTable/:id' },

  // User -> Address  API
  getUserAddress: { type: 'GET', endpoint: '/admin/userAddress' },
  addUserAddress: { type: 'POST', endpoint: '/admin/userAddress' },
  deleteUserAddress: { type: 'DELETE', endpoint: '/admin/userAddress' },
  editUserAddress: { type: 'PATCH', endpoint: '/admin/userAddress' },

  // Booking Api
  getNoAttendedBooking: {
    type: 'GET',
    endpoint: '/admin/booking/individualNotAttendList?status=pending',
  },

  // Assign API
  getAssignBooking: { type: 'GET', endpoint: '/admin/bookingAssign' },
  getAssignGig: {
    type: 'GET',
    endpoint: '/admin/bookingAssign/checkGigForBookingAssign/:id',
  },
  addAssignBooking: { type: 'POST', endpoint: '/admin/bookingAssign' },
  editAssignBooking: { type: 'PATCH', endpoint: '/admin/bookingAssign/:id' },

  // Update Booking Gig Status API
  updatedBookingGigStatus: { type: 'POST', endpoint: '/admin/booking/updateIndividualStatus/:id' },

  // Cancel Booking API
  cancelBooking: { type: 'GET', endpoint: '/admin/booking/cancelBooking' },

  // Update Assign Status API
  editAssignStatus: {
    type: 'PATCH',
    endpoint: '/admin/bookingAssign/updateStatus',
  },

  // Order API
  getOrders: { type: 'GET', endpoint: '/admin/order?isPaid=1' },
  getUpcomingOrders: { type: 'GET', endpoint: '/admin/order/upComing' },
  getOngoingOrders: { type: 'GET', endpoint: '/admin/order/onGoing' },
  getUpcomingGigOrders: { type: 'GET', endpoint: '/admin/order/gig/upcoming' },
  getOngoingGigOrders: { type: 'GET', endpoint: '/admin/order/gig/onGoing' },
  getCompletedOrders: { type: 'GET', endpoint: '/admin/order/custom-getAll?isPaid=1' },

  getOrderAssign: {
    type: 'GET',
    endpoint: '/admin/order/checkGigForBookingAssignWithOrder/:id',
  },
  // Order Assign to Gig API
  orderAssigntoGig: {
    type: 'POST',
    endpoint: '/admin/order/bookingAssignToOrder',
  },

  // Order Cancel API
  orderCancel: { type: 'POST', endpoint: '/admin/order/cancelBookingOnOrder' },

  // Order Status API
  updateOrderStatus: {
    type: 'PATCH',
    endpoint: '/admin/order/updateStatusOfBookingAssign',
  },

  // Reschedule Order API
  rescheduleOrder: {
    type: 'POST',
    endpoint: '/admin/order/rescheduleBookingOnOrder',
  },

  // Reschedule Booking API
  rescheduleGig: { type: 'POST', endpoint: '/admin/booking/rescheduleBooking' },

  // Receipt API
  getOrderReceipt: {
    type: 'GET',
    endpoint: '/admin/receipt',
  },
  updateGigPaymentStatus: {
    type: 'POST',
    endpoint: 'admin/payment/create-payment',
  },
  getGigPaymentStatus: {
    type: 'GET',
    endpoint: '/admin/order/getGigPaymentInfo/:id',
  },


  // Gigs API
  getGigs: { type: 'GET', endpoint: '/admin/gigs' },
  addGig: { type: 'POST', endpoint: '/admin/gigs' },
  editGig: { type: 'PATCH', endpoint: '/admin/gigs/:id' },
  deleteGig: { type: 'DELETE', endpoint: '/admin/gigs/:id' },
  getOneGigInfo: { type: 'GET', endpoint: '/admin/gigs/:id' },

  // Gigs API -> Societies API
  getGigSocieties: { type: 'GET', endpoint: '/admin/gigSociety' },
  addGigSocieties: { type: 'POST', endpoint: '/admin/gigSociety' },
  deleteGigSocieties: { type: 'DELETE', endpoint: '/admin/gigSociety/:id' },
  editGigSocieties: { type: 'PATCH', endpoint: '/admin/gigSociety/:id' },

  // Gigs API -> Transaction API
  getGigTransaction: { type: 'GET', endpoint: '/admin/gigTransaction' },

  // Gigs API -> Schedule API
  getGigSchedule: { type: 'GET', endpoint: '/admin/gigSchedule' },
  editGigSchedule: { type: 'PATCH', endpoint: '/admin/gigSchedule/:id' },
  deleteGigSchedule: { type: 'DELETE', endpoint: '/admin/gigSchedule/:id' },

  // Gigs API -> Attendance API
  getGigAttendance: { type: 'GET', endpoint: '/admin/gigAttendance' },
  addGigAttendance: { type: 'POST', endpoint: '/admin/gigAttendance' },
  editGigAttendance: { type: 'PATCH', endpoint: '/admin/gigAttendance/:id' },

  // Gigs API -> Service API
  getGigService: { type: 'GET', endpoint: '/admin/gigService' },
  addGigService: { type: 'POST', endpoint: '/admin/gigService' },
  editGigService: { type: 'PATCH', endpoint: '/admin/gigService/:id' },
  deleteGigService: { type: 'DELETE', endpoint: '/admin/gigService/:id' },

  // Gigs API -> Gig Info API
  getGigInfo: { type: 'GET', endpoint: '/admin/gigs/gigInfo/:id' },

  // Gigs API -> Gig Ticket API
  getGigTicket: { type: 'GET', endpoint: '/admin/gigTicket' },
  addGigTicket: { type: 'POST', endpoint: '/admin/gigTicket' },
  editGigTicket: { type: 'PATCH', endpoint: '/admin/gigTicket/:id' },

  // Gigs API -> Gig Work API
  getGigWork: { type: 'GET', endpoint: '/admin/order/getGigRequest' },

  // Invoice API
  getInvoice: { type: 'GET', endpoint: '/admin/invoice' },
  getUserInvoice: { type: 'GET', endpoint: '/admin/order/:id' },

  // Banner API
  getBanner: { type: 'GET', endpoint: '/admin/banner' },
  addBanner: { type: 'POST', endpoint: '/admin/banner' },
  editBanner: { type: 'PATCH', endpoint: '/admin/banner/:id' },
  deleteBanner: { type: 'DELETE', endpoint: '/admin/banner/:id' },

  // Feedback  APIs
  getInquiry: { type: 'GET', endpoint: '/admin/inquiries' },

  // Feedback  APIs
  getFeedback: { type: 'GET', endpoint: '/admin/bookingFeedback' },
  getGigFeedback: { type: 'GET', endpoint: '/admin/gigFeedback' },

  // Role and Access API
  getTeam: { type: 'GET', endpoint: '/admins' },
  getOneTeam: { type: 'GET', endpoint: '/admins/:id' },
  addTeam: { type: 'POST', endpoint: '/admins/add' },
  deleteTeam: { type: 'DELETE', endpoint: '/admins/:id' },
  editTeam: { type: 'PATCH', endpoint: '/admins/:id' },

  // Setting  APIs

  // Setting -> Service Category API
  getServiceCategories: { type: 'GET', endpoint: '/admin/serviceCategory' },
  addServiceCategories: { type: 'POST', endpoint: '/admin/serviceCategory' },
  editServiceCategories: {
    type: 'PATCH',
    endpoint: '/admin/serviceCategory/:id',
  },
  deleteServiceCategories: {
    type: 'DELETE',
    endpoint: '/admin/serviceCategory/:id',
  },

  // Setting -> Society & Personal Notification API
  sendNotification: {
    type: 'POST',
    endpoint: '/admins/notification/addPersonalNotification',
  },

  // Setting -> General Notification API
  sendGeneralNotification: {
    type: 'POST',
    endpoint: '/admins/notification/addNotificationToTopic',
  },

  // Setting -> App Config API
  appConfigure: {
    type: 'PATCH',
    endpoint: '/admin/appConfig/1',
  },
  getappConfigure: {
    type: 'GET',
    endpoint: '/admin/appConfig/1',
  },

  // Setting -> City API
  getCitys: { type: 'GET', endpoint: '/admin/city' },
  sendCity: { type: 'POST', endpoint: '/admin/city' },
  deleteCity: { type: 'DELETE', endpoint: '/admin/city/:id' },
  editCity: { type: 'PATCH', endpoint: '/admin/city/:id' },
};

const textRanders = ({ value }) => <>{value}</>;
// const RouterLinkRanders = ({ value }) => (
//   <Link to={`/app${value.link}`}>{value.value}</Link>
// );

const BannerRanders = ({ value }) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = TSTLogo;
  }
  return (
    <div>
      <img
        style={{ height: '20vh', width: '20vw' }}
        src={imageSrc}
        alt={value}
      />
    </div>
  );
};
const ImageRanders = ({ value }) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = TSTLogo;
  }
  return (
    <div>
      <img className="list-item-pic" src={imageSrc} alt={value} />
    </div>
  );
};

const RenderActionButton = ({ value }) => {
  const { buttonLabel, id, onClick, isDisable } = value;
  return (
    <div>
      <Button
        outline
        disabled={isDisable}
        style={{
          cursor: `${isDisable ? 'not-allowed' : 'pointer'}`,
        }}
        onClick={() => {
          onClick(id);
        }}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};
const cancelRequestButton = ({ value }) => {
  const {
    buttonLabel,
    id,
    onClick,
    isDisable,
    isCancelDisable,
    cancelonClick,
    userId,
  } = value;
  return (
    <div>
      <Button
        className={buttonLabel ? 'd-block' : 'd-none'}
        outline
        style={{
          cursor: isDisable ? 'not-allowed' : 'pointer'
        }}
        disabled={isDisable}
        onClick={() => {
          onClick(id);
        }}
      >
        {buttonLabel}
      </Button>
      <Button
        outline
        disabled={isCancelDisable}
        className={buttonLabel ? 'mt-4' : 'mt-0'}
        style={{
          cursor: isCancelDisable ? 'not-allowed' : 'pointer'
        }}
        onClick={() => {
          cancelonClick(id, userId);
        }}
      >
        Cancel
      </Button>
    </div>
  );
};

const AssignGigButton = ({ value }) => {
  const gigAssignStatusColor = {
    pending: '#8c8686',
    approve: '#43a047',
    reject: '#e53935',
    canceled: '#e53935',
    // notAttend: '#e5393580',
  };
  const { buttonLabel, id, onClick, profilePic, status, isDisable, onlyProfilePic = false, name, mobile } = value;
  // console.log(status, id);
  return (
    <div>

      {profilePic ? (
        onlyProfilePic ?
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <img
              className="list-item-pic"
              style={{
                border: `3px solid ${gigAssignStatusColor[status]}`,
                cursor: 'pointer',
              }}

              src={profilePic}
              alt={buttonLabel}
            />
            <p className='mb-0 text-center'>
              {name}
            </p>
            <p>
              {mobile}
            </p>
          </div>
          :
          <> <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Button
              style={{ background: 'transparent', border: '0', }}

              onClick={() => {
                onClick(id);
              }}
            >
              <img
                className="list-item-pic"
                style={{
                  border: `3px solid ${gigAssignStatusColor[status]}`,
                  cursor: 'pointer',
                }}

                src={profilePic}
                alt={buttonLabel}
              />

            </Button>
            <p className='mb-0 text-center'>
              {name}
            </p>
            <p>
              {mobile}
            </p>
          </div>
          </>
      ) : (
        <Button
          outline
          disabled={isDisable}
          style={{
            cursor: isDisable ? 'not-allowed' : 'pointer'
          }}
          onClick={() => {
            onClick(id);
          }}
        >
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

const RenderToggleButton = ({ value }) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <SwitchExamples
        onChange={() => {
          onClick(id, checked);
        }}
        checked={!checked}
      />
    </div>
  );
};

const RenderDropDown = ({ value }) => {
  const { defaultValue, onChange, options, bookingStatus, orderStatus } = value
  return (
    <>
      {/* {defaultValue === undefined ? <div>{mappedStatus[defaultValue]}</div> : ((defaultValue === 'approve' || defaultValue === 'notAttend' || bookingStatus === 'pending') && bookingStatus !== 'complete') ? <Input */}
      {defaultValue === undefined || bookingStatus === 'complete' || orderStatus === 'complete' ? <div>{mappedStatus[defaultValue]}</div> : (((defaultValue === 'approve' || defaultValue === 'notAttend') && (bookingStatus === 'approve' || bookingStatus === 'pending'))) ? <Input
        id='status'
        type='select'
        size="1"
        defaultValue={defaultValue}

        onChange={onChange}
      >

        {options &&
          options.length > 0 &&
          options.map((item) => (
            <option key={`role_${item.value}`} value={item.value}>
              {item.label ? item.label : item.value}
            </option>
          ))}


      </Input> : <div>{mappedStatus[defaultValue]}</div>}
    </>
  )

}

const CONSTANTS = {
  TABLE_ID: {
    add: 'modal.button',
    update: 'modal.update',
    addModal: 'advisor.add-new-modal-title',


    // For User
    addUserButton: 'button.add.user',
    userModalTitle: 'modal.user.title',

    // For Service and Tower Service
    addServiceButton: 'button.add.service',
    serviceModalTitle: 'modal.service.title',

    // For Gigs
    addGigsButton: 'button.add.gigs',
    gigsModalTitle: 'modal.gigs.title',
    addscheduleButton: 'button.add.schedule',
    scheduleTitle: 'modal.schedule.title',
    addInfoButton: 'button.add.info',
    infoTitle: 'modal.info.title',

    // For Societies
    addSocietyButton: 'button.add.society',
    addSocietyTitle: 'modal.society.title',

    // For Tower
    addTowerButton: 'button.add.tower',
    towerModalTitle: 'modal.tower.title',

    // For Flats
    addFlatButton: 'button.add.flat',
    flatModalTitle: 'modal.flat.title',

    // For Parking
    addParkingButton: 'button.add.parking',
    parkingModalTitle: 'modal.parking.title',

    // For Attendance
    addAttendanceButton: 'button.add.attendance',
    attendanceTitle: 'modal.attendance.title',

    // For Feedback
    addFeedbackButton: 'button.add.feedback',
    feedbackModalTitle: 'modal.feedback.title',

    // For Role and Access
    addRole_and_AccessButton: 'button.add.role_and_acces',
    role_and_accessModalTitle: 'modal.role_and_acces.title',

    // For Setting
    settingModalTitle: 'modal.category.title',
  },

  TABLE_HEADER: {
    USER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Mobile',
        accessor: 'mobile',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Block User',
        accessor: 'toggle',
        cellClass: 'list-item-heading w-10',
        Cell: RenderToggleButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    USER_SERVICE_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },

      {
        Header: 'Service Name',
        accessor: 'service',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Order Status',
        accessor: 'orderStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Order Created Date',
        accessor: 'bookingDate',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Total Charge',
        accessor: 'totalPrice',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
    ],
    USER_REQUEST_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },

      {
        Header: 'Service Name',
        accessor: 'service',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Order Status',
        accessor: 'orderStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Order Created Date',
        accessor: 'bookingDate',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Total Charge',
        accessor: 'totalPrice',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
    ],

    NOT_ATTENDED_BOOKING_REQUEST_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Order Details',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Start Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Time Slot',
        accessor: 'time',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },

      {
        Header: 'Booking Status',
        accessor: 'bookingStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      // {
      //   Header: 'Gig Status',
      //   accessor: 'status',
      //   cellClass: 'list-item-heading w-15',
      //   Cell: textRanders,
      // },
      {
        Header: 'Gig Status',
        accessor: 'gigStatus',
        cellClass: 'list-item-heading w-15',
        Cell: RenderDropDown,
      },
      {
        Header: 'Action',
        accessor: 'assign',
        cellClass: 'list-item-heading w-10',
        Cell: AssignGigButton,
      },
      {
        Header: '',
        accessor: 'reschedule',
        cellClass: 'list-item-heading w-10',
        Cell: cancelRequestButton,
      },
    ],
    BOOKING_REQUEST_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Start Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Time Slot',
        accessor: 'time',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },

      {
        Header: 'Booking Status',
        accessor: 'bookingStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      // {
      //   Header: 'Gig Status',
      //   accessor: 'status',
      //   cellClass: 'list-item-heading w-15',
      //   Cell: textRanders,
      // },
      {
        Header: 'Gig Status',
        accessor: 'gigStatus',
        cellClass: 'list-item-heading w-15',
        Cell: RenderDropDown,
      },
      {
        Header: 'Action',
        accessor: 'assign',
        cellClass: 'list-item-heading w-10',
        Cell: AssignGigButton,
      },
      {
        Header: '',
        accessor: 'reschedule',
        cellClass: 'list-item-heading w-10',
        Cell: cancelRequestButton,
      },
    ],


    USER_ORDER_HISTORY_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },

      {
        Header: 'Service Name',
        accessor: 'service',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Order Status',
        accessor: 'status',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Order Created Date',
        accessor: 'bookingDate',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Total Charge',
        accessor: 'totalPrice',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        // user receipt download button
        Header: 'Receipt',
        accessor: 'receipt',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        // user invoice download button
        Header: 'Invoice',
        accessor: 'user',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    USER_TRANSACTION_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Date & Time',
        accessor: 'date',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      // {
      //   Header: 'Transaction ID',
      //   accessor: 'trId',
      //   cellClass: 'list-item-heading w-15',
      //   Cell: textRanders,
      // },
      {
        Header: 'Amount',
        accessor: 'totalPrice',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Status',
        accessor: 'status',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
    ],
    USER_TICKET_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Time',
        accessor: 'time',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Comment',
        accessor: 'message',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Resolved By',
        accessor: 'resolvedBy',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Resolved',
        accessor: 'toggle',
        cellClass: 'list-item-heading w-15',
        Cell: RenderToggleButton,
      },
    ],
    USER_ADDRESS_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'City',
        accessor: 'city',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Society',
        accessor: 'society',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Address',
        accessor: 'address',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Tower',
        accessor: 'tower',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Room',
        accessor: 'room',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Parking Number',
        accessor: 'parkingNumber',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
    ],
    USER_FEEDBACK_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Order ID',
        accessor: 'orderId',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Gig Name',
        accessor: 'gig',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Gig Mobile',
        accessor: 'gigMobile',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Ratings',
        accessor: 'rating',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Message',
        accessor: 'feedback',
        cellClass: 'list-item-heading w-35',
        Cell: textRanders,
      },
    ],
    SOCIETIES_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Address',
        accessor: 'address',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'City',
        accessor: 'city',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Block Society',
        accessor: 'toggle',
        cellClass: 'list-item-heading w-10',
        Cell: RenderToggleButton,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      // {
      //   Header: 'Action',
      //   accessor: 'action',
      //   cellClass: 'list-item-heading w-5',
      //   Cell: RenderActionButton,
      // },
    ],
    TOWER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Tower Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-40',
        Cell: textRanders,
      },

      {
        Header: 'Block Tower',
        accessor: 'toggle',
        cellClass: 'list-item-heading w-10',
        Cell: RenderToggleButton,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
    ],
    FLAT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      // {
      //   Header: 'Preview',
      //   accessor: 'view',
      //   cellClass: 'list-item-heading w-10',
      //   Cell: RenderActionButton,
      // },

      {
        Header: 'Tower Name',
        accessor: 'tower',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Flat Number',
        accessor: 'name',
        cellClass: 'list-item-heading w-40',
        Cell: textRanders,
      },

      {
        Header: 'Block Flat',
        accessor: 'toggle',
        cellClass: 'list-item-heading w-10',
        Cell: RenderToggleButton,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
    ],
    SOCIETIES_SERVICE_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: 'Service Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Daily Price',
        accessor: 'perDayCost',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Monthly Price',
        accessor: 'perMonthCost',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
    ],
    PARKING_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Parking Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-35 ',
        Cell: textRanders,
      },

      {
        Header: 'Block Room',
        accessor: 'toggle',
        cellClass: 'list-item-heading w-10',
        Cell: RenderToggleButton,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
    ],
    SOCIETIES_USER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Mobile number',
        accessor: 'mobile',
        cellClass: 'list-item-heading w-65',
        Cell: textRanders,
      },
      {
        Header: 'Block User',
        accessor: 'toggle',
        cellClass: 'list-item-heading w-10',
        Cell: RenderToggleButton,
      },
    ],
    SOCIETIES_GIGS_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Service',
        accessor: 'service',
        cellClass: 'list-item-heading w-45',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'delete',
        cellClass: 'list-item-heading w-20',
        Cell: RenderActionButton,
      },
    ],
    SERVICE_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },

      {
        Header: 'Service Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Service Unit',
        accessor: 'unit',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Label',
        accessor: 'label',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Commission',
        accessor: 'generalCharge',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Category',
        accessor: 'category',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },

      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    SERVICE_VARIETY_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Variety Name',
        accessor: 'optionName',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
    ],
    UPCOMING_ORDER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Order Type',
        accessor: 'type',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Service',
        accessor: 'service',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Slot 1',
        accessor: 'slot1',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Slot 2',
        accessor: 'slot2',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Gig Name',
        accessor: 'gigName',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Order Status',
        accessor: 'orderStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

    ],
    ONGOING_ORDER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Order Type',
        accessor: 'type',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Service',
        accessor: 'service',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Slot 1',
        accessor: 'slot1',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Slot 2',
        accessor: 'slot2',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      // {
      //   Header: 'Gig Name',
      //   accessor: 'gigName',
      //   cellClass: 'list-item-heading w-10',
      //   Cell: textRanders,
      // },

      {
        Header: 'Order Status',
        accessor: 'orderStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        // user receipt download button
        Header: 'Receipt',
        accessor: 'receipt',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },

    ],
    COMPLETED_ORDER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Order Type',
        accessor: 'type',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Service',
        accessor: 'service',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },


      // {
      //   Header: 'Gig Name',
      //   accessor: 'gigName',
      //   cellClass: 'list-item-heading w-10',
      //   Cell: textRanders,
      // },
      // {
      //   Header: 'Leave',
      //   accessor: 'leave',
      //   cellClass: 'list-item-heading w-10',
      //   Cell: textRanders,
      // },
      {
        // user receipt download button
        Header: 'User Receipt',
        accessor: 'receipt',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        // user invoice download button
        Header: 'User Invoice',
        accessor: 'user',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },

      // {
      //   // maid invoice download button
      //   Header: 'Gig Receipt',
      //   accessor: 'gig',
      //   cellClass: 'list-item-heading w-10',
      //   Cell: RenderActionButton,
      // },

    ],
    USERS_ORDER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Order Type',
        accessor: 'type',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Service',
        accessor: 'service',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'CreatedAt',
        accessor: 'bookingTime',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Order Status',
        accessor: 'orderStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Gig Status',
        accessor: 'gigStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'assign',
        cellClass: 'list-item-heading w-10',
        Cell: AssignGigButton,
      },
      {
        Header: '',
        accessor: 'reschedule',
        cellClass: 'list-item-heading w-10',
        Cell: cancelRequestButton,
      },
    ],
    APPROVE_ORDER_REQUEST_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Order Type',
        accessor: 'type',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Service',
        accessor: 'service',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'CreatedAt',
        accessor: 'bookingTime',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Order Status',
        accessor: 'orderStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Action',
        accessor: 'assign',
        cellClass: 'list-item-heading w-10',
        Cell: AssignGigButton,
      },
      {
        Header: '',
        accessor: 'reschedule',
        cellClass: 'list-item-heading w-10',
        Cell: cancelRequestButton,
      },
    ],
    ASSIGN_ORDER_REQUEST_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Order Type',
        accessor: 'type',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Service',
        accessor: 'service',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'CreatedAt',
        accessor: 'bookingTime',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Order Status',
        accessor: 'orderStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Gig Status',
        accessor: 'gigStatus',
        cellClass: 'list-item-heading w-15',
        Cell: RenderDropDown,
      },
      {
        Header: 'Action',
        accessor: 'assign',
        cellClass: 'list-item-heading w-10',
        Cell: AssignGigButton,
      },
      {
        Header: '',
        accessor: 'reschedule',
        cellClass: 'list-item-heading w-10',
        Cell: cancelRequestButton,
      },
    ],

    CANCEL_USERS_REQUEST_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'View',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Order Type',
        accessor: 'type',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Service',
        accessor: 'service',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'CreatedAt',
        accessor: 'bookingTime',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Order Status',
        accessor: 'orderStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

    ],
    GIG_ORDER_HISTORY_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'View Details',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Service',
        accessor: 'service',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'CreatedAt',
        accessor: 'bookingTime',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Order Status',
        accessor: 'bookingStatus',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Paid Amount',
        accessor: 'paidAmount',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
    ],
    BANNER_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Banner',
        accessor: 'banner',
        cellClass: 'list-item-pic w-20',
        Cell: BannerRanders,
      },

      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    GIGS_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Profile',
        accessor: 'profilePic',
        cellClass: 'list-item-pic w-10',
        Cell: ImageRanders,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Security Code',
        accessor: 'securityCode',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'mobile',
        accessor: 'mobile',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Leave',
        accessor: 'leave',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Block Gig',
        accessor: 'toggle',
        cellClass: 'list-item-heading w-10',
        Cell: RenderToggleButton,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    GIGS_TRANSACTION_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Time',
        accessor: 'time',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Transaction ID',
        accessor: 'trId',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
    ],
    GIGS_SOCIETY_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Society Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-40',
        Cell: textRanders,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-15',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'delete',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    GIGS_SCHEDULE_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Start Time',
        accessor: 'startTime',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'End Time',
        accessor: 'endTime',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'delete',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      }
    ],
    GIGS_ATTENDANCE_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-30',
        Cell: textRanders,
      },
      {
        Header: 'Time',
        accessor: 'time',
        cellClass: 'list-item-heading w-25',
        Cell: textRanders,
      },
      {
        Header: 'Society',
        accessor: 'society',
        cellClass: 'list-item-heading w-30',
        Cell: textRanders,
      },
      {
        Header: 'Present',
        accessor: 'present',
        cellClass: 'list-item-heading w-30',
        Cell: textRanders,
      },
      {
        Header: 'Absent',
        accessor: 'absent',
        cellClass: 'list-item-heading w-30',
        Cell: textRanders,
      },
    ],
    GIGS_SERVICE_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Service',
        accessor: 'service',
        cellClass: 'list-item-heading w-80',
        Cell: textRanders,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    GIGS_FEEDBACK_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Order ID',
        accessor: 'orderId',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'User Name',
        accessor: 'userName',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'User Mobile',
        accessor: 'userMobile',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Ratings',
        accessor: 'rating',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Message',
        accessor: 'feedback',
        cellClass: 'list-item-heading w-35',
        Cell: textRanders,
      },
    ],
    GIGS_TICKET_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Time',
        accessor: 'time',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Comment',
        accessor: 'message',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Resolved By',
        accessor: 'resolvedBy',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Resolved',
        accessor: 'toggle',
        cellClass: 'list-item-heading w-15',
        Cell: RenderToggleButton,
      },
    ],
    GIGS_WORK_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Start Time',
        accessor: 'time',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Order Status',
        accessor: 'status',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Action',
        accessor: 'accept',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: '',
        accessor: 'reject',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
    ],
    INQUIRY_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading W-10',
        Cell: textRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading W-10',
        Cell: textRanders,
      },
      {
        Header: 'Email',
        accessor: 'email',
        cellClass: 'list-item-heading W-10',
        Cell: textRanders,
      },
      {
        Header: 'Mobile',
        accessor: 'mobile',
        cellClass: 'list-item-heading W-10',
        Cell: textRanders,
      },
      {
        Header: 'Society',
        accessor: 'societyName',
        cellClass: 'list-item-heading W-10',
        Cell: textRanders,
      },
      {
        Header: 'Created Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
    ],
    USER_RECEIPT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'User Name',
        accessor: 'uname',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        // user receipt download button
        Header: 'User Receipt',
        accessor: 'receipt',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      // {
      //   // user invoice download button
      //   Header: 'User Invoice',
      //   accessor: 'user',
      //   cellClass: 'list-item-heading w-10',
      //   Cell: RenderActionButton,
      // },
      // {
      //   Header: 'Gig Name',
      //   accessor: 'gigName',
      //   cellClass: 'list-item-heading w-10',
      //   Cell: textRanders,
      // },
      // {
      //   // maid invoice download button
      //   Header: 'Gig Receipt',
      //   accessor: 'gigReceipt',
      //   cellClass: 'list-item-heading w-10',
      //   Cell: RenderActionButton,
      // },
      {
        Header: 'Service Name',
        accessor: 'service',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Status',
        accessor: 'orderStatus',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'CreatedAt',
        accessor: 'createdAt',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      // {
      //   Header: 'Total Charge',
      //   accessor: 'totalPrice',
      //   cellClass: 'list-item-heading w-10',
      //   Cell: textRanders,
      // },
    ],
    GIG_RECEIPT_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },


      {
        Header: 'Payment Details',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Gig Name',
        accessor: 'gigName',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        // maid invoice download button
        Header: 'Gig Receipt',
        accessor: 'gigReceipt',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Service Name',
        accessor: 'service',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'CreatedAt',
        accessor: 'createdAt',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'isGigPaid',
        accessor: 'toggle',
        cellClass: 'list-item-heading w-10',
        Cell: RenderToggleButton,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      // {
      //   Header: 'Total Charge',
      //   accessor: 'totalPrice',
      //   cellClass: 'list-item-heading w-10',
      //   Cell: textRanders,
      // },
    ],
    INVOICE_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'User Name',
        accessor: 'uname',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Service Name',
        accessor: 'service',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'CreatedAt',
        accessor: 'createdAt',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },

      {
        Header: 'Total Charge',
        accessor: 'totalPriceD',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        // user invoice download button
        Header: 'User Invoice',
        accessor: 'user',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    FEEDBACK_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Order ID',
        accessor: 'orderId',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'User Name',
        accessor: 'uname',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Gig Name',
        accessor: 'gigName',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Gig Mobile',
        accessor: 'gigMobile',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Ratings',
        accessor: 'rating',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Message',
        accessor: 'feedback',
        cellClass: 'list-item-heading w-25',
        Cell: textRanders,
      },
    ],
    ROLE_AND_ACCESS_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Mobile Number',
        accessor: 'mobile',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Email',
        accessor: 'email',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Created Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
    SETTING_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },

      {
        Header: 'Service Category Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-60',
        Cell: textRanders,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
    ],
    ATTENDACNE_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-5',
        Cell: RenderActionButton,
      },
      {
        Header: 'Profile',
        accessor: 'profilePic',
        cellClass: 'list-item-pic w-5',
        Cell: ImageRanders,
      },
      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Date',
        accessor: 'date',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Society',
        accessor: 'society',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'Entry Time',
        accessor: 'entryTime',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Exit Time',
        accessor: 'exitTime',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Clock-out',
        accessor: 'out',
        cellClass: 'list-item-heading w-15',
        Cell: RenderActionButton,
      },
    ],
    GIG_LEAVE_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Preview',
        accessor: 'view',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Profile',
        accessor: 'profilePic',
        cellClass: 'list-item-pic w-10',
        Cell: ImageRanders,
      },

      {
        Header: 'Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'Security Code',
        accessor: 'securityCode',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },

      {
        Header: 'mobile',
        accessor: 'mobile',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Leave',
        accessor: 'leave',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
    ],
    SETTING_CITY_LIST: [
      {
        Header: 'No.',
        accessor: 'no',
        cellClass: 'list-item-heading w-5',
        Cell: textRanders,
      },
      {
        Header: 'Profile',
        accessor: 'profilePic',
        cellClass: 'list-item-pic w-10',
        Cell: ImageRanders,
      },
      {
        Header: 'City Name',
        accessor: 'name',
        cellClass: 'list-item-heading w-15',
        Cell: textRanders,
      },
      {
        Header: 'CGST',
        accessor: 'CGST',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'IGST',
        accessor: 'IGST',
        cellClass: 'list-item-heading w-10',
        Cell: textRanders,
      },
      {
        Header: 'SGST',
        accessor: 'SGST',
        cellClass: 'list-item-heading w-20',
        Cell: textRanders,
      },
      {
        Header: 'Edit',
        accessor: 'edit',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
      {
        Header: 'Action',
        accessor: 'action',
        cellClass: 'list-item-heading w-10',
        Cell: RenderActionButton,
      },
    ],
  },

  RIGHT_SIDEBAR_FIELD: {
    USER_MODAL: [
      {
        no: 0,
        Label: 'form.user.profilePic',
        name: 'profilePic',
        id: 'profilePic',
        type: 'file',
        required: true,
      },
      {
        no: 1,
        Label: 'form.user.name',
        name: 'userName',
        id: 'userName',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'form.user.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: true,
      },
      {
        no: 3,
        Label: 'form.user.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',
        required: true,
      },
    ],
    USER_INFO_MODAL: [
      {
        no: 0,
        Label: 'form.gigs.msg',
        name: 'message',
        id: 'message',
        type: 'text',
        required: true,
      },
    ],
    SERVICE_MODAL: [
      {
        no: 0,
        Label: 'form.service.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'form.service.unit',
        name: 'unit',
        id: 'unit',
        type: 'text',
        required: false,
      },
      {
        no: 2,
        Label: 'form.service.label',
        name: 'label',
        id: 'label',
        type: 'select',
        option: [
          {
            value: 'No Label',
            label: 'No Label',
          },
          {
            value: 'New',
            label: 'New',
          },

          {
            value: 'Upcoming',
            label: 'Upcoming',
          },
          {
            value: 'Popular',
            label: 'Popular',
          },
        ],
        required: false,
      },
      {
        no: 3,
        Label: 'form.service.type',
        name: 'serviceCategoryId',
        id: 'serviceCategoryId',
        type: 'select',

        required: false,
      },
      {
        no: 4,
        Label: 'form.service.slot',
        name: 'allowSecondSlot',
        id: 'allowSecondSlot',
        type: 'switch',

        required: false,
      },
      {
        no: 5,
        Label: 'form.service.charge',
        name: 'generalCharge',
        id: 'generalCharge',
        type: 'number',

        required: true,
      },
      {
        no: 6,
        Label: 'form.service.image',
        name: 'image',
        id: 'image',
        type: 'file',
        required: true,
      },
      {
        no: 7,
        Label: '+ Add',
        name: 'add',
        id: 'add',
        type: 'include',
        required: true,
      },
    ],
    EDIT_SERVICE_MODAL: [
      {
        no: 0,
        Label: 'form.service.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'form.service.unit',
        name: 'unit',
        id: 'unit',
        type: 'text',
        required: false,
      },
      {
        no: 2,
        Label: 'form.service.label',
        name: 'label',
        id: 'label',
        type: 'select',
        option: [
          {
            value: 'No Label',
            label: 'No Label',
          },
          {
            value: 'New',
            label: 'New',
          },

          {
            value: 'Upcoming',
            label: 'Upcoming',
          },
          {
            value: 'Popular',
            label: 'Popular',
          },
        ],
        required: false,
      },
      {
        no: 3,
        Label: 'form.service.type',
        name: 'serviceCategoryId',
        id: 'serviceCategoryId',
        type: 'select',

        required: false,
      },
      {
        no: 4,
        Label: 'form.service.slot',
        name: 'allowSecondSlot',
        id: 'allowSecondSlot',
        type: 'switch',

        required: false,
      },
      {
        no: 5,
        Label: 'form.service.charge',
        name: 'generalCharge',
        id: 'generalCharge',
        type: 'number',

        required: true,
      },
      {
        no: 6,
        Label: 'form.service.image',
        name: 'image',
        id: 'image',
        type: 'file',
        required: false,
      },
      {
        no: 7,
        Label: '+ Add',
        name: 'add',
        id: 'add',
        type: 'include',
        required: true,
      },
    ],
    SERVICE_VARIETY_MODAL: [
      {
        no: 0,
        Label: 'service.label',
        name: 'varietyLabel',
        id: 'varietyLabel',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'Variety option',
        name: 'optionName',
        id: 'optionName',
        type: 'addvariety',

        required: true,
      },
    ],
    EDIT_SERVICE_VARIETY_MODAL: [
      {
        no: 0,
        Label: 'service.label',
        name: 'varietyLabel',
        id: 'varietyLabel',
        type: 'text',
        required: true,
      },
    ],
    SERVICE_VARIETY_CARD_MODAL: [
      {
        no: 0,
        Label: 'Variety Option',
        name: 'optionName',
        id: 'optionName',
        type: 'addvariety',

        required: true,
      },
    ],
    EDIT_SERVICE_VARIETY_CARD_MODAL: [
      {
        no: 0,
        Label: 'variety.option',
        name: 'optionName',
        id: 'optionName',
        type: 'text',

        required: true,
      },
    ],
    SOCIETIES_MODAL: [
      {
        no: 0,
        Label: 'form.society.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 0,
        Label: 'form.society.charge',
        name: 'serviceCharge',
        id: 'serviceCharge',
        type: 'number',
        required: false,
      },
      {
        no: 1,
        Label: 'form.society.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',
        required: true,
      },
      {
        no: 2,
        Label: 'form.society.person',
        name: 'personInCharge',
        id: 'personInCharge',
        type: 'text',
        required: true,
      },
      {
        no: 3,
        Label: 'form.society.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: true,
      },
      {
        no: 4,
        Label: 'user.password',
        name: 'password',
        id: 'password',
        type: 'password',
        required: true,
      },
      {
        no: 5,
        Label: 'form.society.address',
        name: 'address',
        id: 'address',
        type: 'text',
        required: true,
      },
      {
        no: 6,
        Label: 'form.society.city',
        name: 'city',
        id: 'cityId',
        type: 'select',

        required: false,
      },
      {
        no: 7,
        Label: 'form.society.document',
        name: 'document',
        id: 'document',
        type: 'file',

        required: false,
      },
    ],
    SOCIETIEY_GIG_MODAL: [
      {
        no: 0,
        Label: 'Select Gigs',
        name: 'gigId',
        id: 'gigId',
        type: 'mulitpleSelect',

        required: true,
      },
    ],
    EDIT_PASSWORD_MODAL: [
      {
        no: 0,
        Label: 'user.password',
        name: 'password',
        id: 'password',
        type: 'password',
        required: true,
      },
    ],
    EDIT_SOCIETIES_MODAL: [
      {
        no: 0,
        Label: 'form.society.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'form.society.charge',
        name: 'serviceCharge',
        id: 'serviceCharge',
        type: 'number',
        required: false,
      },
      {
        no: 2,
        Label: 'form.society.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',
        required: true,
      },
      {
        no: 3,
        Label: 'form.society.person',
        name: 'personInCharge',
        id: 'personInCharge',
        type: 'text',
        required: true,
      },
      {
        no: 4,
        Label: 'form.society.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: true,
      },

      {
        no: 5,
        Label: 'form.society.address',
        name: 'address',
        id: 'address',
        type: 'text',
        required: true,
      },
      {
        no: 6,
        Label: 'form.society.city',
        name: 'city',
        id: 'cityId',
        type: 'select',

        required: false,
      },
      {
        no: 7,
        Label: 'form.society.document',
        name: 'document',
        id: 'document',
        type: 'file',

        required: false,
      },
    ],
    EDIT_TOWER_MODAL: [
      {
        no: 0,
        Label: 'form.tower.name',
        name: 'name',
        id: 'name',
        type: 'text',

        required: true,
      },
    ],
    TOWER_MODAL: [
      {
        no: 0,
        Label: 'Tower Name',
        name: 'name',
        id: 'name',
        type: 'addvariety',

        required: true,
      },
    ],
    FLAT_MODAL: [
      {
        no: 0,
        Label: 'form.room.tower.name',
        name: 'towerId',
        id: 'towerId',
        type: 'select',

        required: false,
      },
      {
        no: 1,
        Label: 'Flat Number',
        name: 'name',
        id: 'name',
        type: 'addvariety',

        required: true,
      },
    ],
    BULK_FLAT_MODAL: [
      {
        no: 0,
        Label: 'form.room.tower.name',
        name: 'towerId',
        id: 'towerId',
        type: 'select',

        required: false,
      },
      {
        no: 1,
        Label: 'Import CSV Data',
        name: 'csv',
        id: 'csv',
        type: 'file',
        accept: '.csv',

        required: true,
      },
    ],
    EDIT_FLAT_MODAL: [
      {
        no: 0,
        Label: 'form.room.tower.name',
        name: 'towerId',
        id: 'towerId',
        type: 'select',

        required: false,
      },
      {
        no: 1,
        Label: 'form.flat.option',
        name: 'name',
        id: 'name',
        type: 'text',

        required: true,
      },
    ],
    SOCIETIES_SERVICE_MODAL: [
      // {
      //   no: 0,
      //   Label: 'form.tower.servic.name',
      //   name: 'serviceId',
      //   id: 'serviceId',
      //   type: 'select',

      //   required: false,
      // },

      {
        no: 0,
        Label: 'form.tower.servic.name',
        name: 'service',
        id: 'service',
        type: 'variety',
        required: false,
      },

      {
        no: 1,
        Label: 'form.tower.servic.day',
        name: 'perDayCost',
        id: 'perDayCost',
        type: 'text',
        required: false,
      },
      {
        no: 2,
        Label: 'form.tower.servic.month',
        name: 'perMonthCost',
        id: 'perMonthCost',
        type: 'text',
        required: false,
      },

      {
        no: 3,
        Label: 'Add Time',
        name: 'time',
        id: 'time',
        type: 'addtime',

        required: false,
      },
    ],
    PARKING_MODAL: [
      {
        no: 0,
        Label: 'Parking Name',
        name: 'name',
        id: 'name',
        type: 'addvariety',

        required: true,
      },
    ],
    EDIT_PARKING_MODAL: [
      {
        no: 0,
        Label: 'form.parking.name',
        name: 'name',
        id: 'name',
        type: 'text',

        required: true,
      },
    ],
    GIGS_MODAL: [
      {
        no: 0,
        Label: 'form.gigs.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'form.gigs.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',
        required: true,
      },
      {
        no: 2,
        Label: 'form.gigs.paymentType',
        name: 'paymentType',
        id: 'paymentType',
        type: 'select',
        option: [

          {
            value: 'bank',
            label: 'Bank Account Transfer',
          },

          {
            value: 'upiId',
            label: 'UPI',
          },

        ],
        required: false,
      },

      {
        no: 3,
        Label: 'form.gigs.bankName',
        name: 'bankName',
        id: 'bankName',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.accountHolderName',
        name: 'accountHolderName',
        id: 'accountHolderName',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.accountNumber',
        name: 'accountNumber',
        id: 'accountNumber',
        type: 'number',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.ifscCode',
        name: 'ifscCode',
        id: 'ifscCode',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.branchCode',
        name: 'branchCode',
        id: 'branchCode',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.upiId',
        name: 'upiId',
        id: 'upiId',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.profilePic',
        name: 'profilePic',
        id: 'profilePic',
        type: 'file',
        required: true,
      },

      {
        no: 4,
        Label: 'form.gigs.aadhar',
        name: 'aadharCard',
        id: 'aadharCard',
        type: 'file',
        required: false,
      },
      {
        no: 5,
        Label: 'form.gigs.pan',
        name: 'panCard',
        id: 'panCard',
        type: 'file',
        required: false,
      },
      {
        no: 6,
        Label: 'form.gigs.ration',
        name: 'rationCard',
        id: 'rationCard',
        type: 'file',
        required: false,
      },

      {
        no: 7,
        Label: 'form.gigs.other',
        name: 'other',
        id: 'other',
        type: 'file',
        required: false,
      },
    ],
    EDIT_GIGS_MODAL: [
      {
        no: 0,
        Label: 'form.gigs.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'form.gigs.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',
        required: true,
      },
      // {
      //   no: 2,
      //   Label: 'form.gigs.society',
      //   name: 'societyId',
      //   id: 'societyId',
      //   type: 'select',

      //   required: true,
      // },
      {
        no: 3,
        Label: 'form.gigs.profilePic',
        name: 'profilePic',
        id: 'profilePic',
        type: 'file',
        required: false,
      },

      {
        no: 4,
        Label: 'form.gigs.aadhar',
        name: 'aadharCard',
        id: 'aadharCard',
        type: 'file',
        required: false,
      },
      {
        no: 5,
        Label: 'form.gigs.pan',
        name: 'panCard',
        id: 'panCard',
        type: 'file',
        required: false,
      },
      {
        no: 6,
        Label: 'form.gigs.ration',
        name: 'rationCard',
        id: 'rationCard',
        type: 'file',
        required: false,
      },

      {
        no: 7,
        Label: 'form.gigs.other',
        name: 'other',
        id: 'other',
        type: 'file',
        required: false,
      },
    ],
    EDIT_GIGS_BANK_MODAL: [
      {
        no: 0,
        Label: 'form.gigs.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'form.gigs.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',
        required: true,
      },
      {
        no: 2,
        Label: 'form.gigs.paymentType',
        name: 'paymentType',
        id: 'paymentType',
        type: 'select',
        option: [

          {
            value: 'bank',
            label: 'Bank Account Transfer',
          },

          {
            value: 'upiId',
            label: 'UPI',
          },

        ],
        required: false,
      },

      {
        no: 3,
        Label: 'form.gigs.bankName',
        name: 'bankName',
        id: 'bankName',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.accountHolderName',
        name: 'accountHolderName',
        id: 'accountHolderName',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.accountNumber',
        name: 'accountNumber',
        id: 'accountNumber',
        type: 'number',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.ifscCode',
        name: 'ifscCode',
        id: 'ifscCode',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.branchCode',
        name: 'branchCode',
        id: 'branchCode',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.upiId',
        name: 'upiId',
        id: 'upiId',
        type: 'text',
        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.profilePic',
        name: 'profilePic',
        id: 'profilePic',
        type: 'file',
        required: false,
      },

      {
        no: 4,
        Label: 'form.gigs.aadhar',
        name: 'aadharCard',
        id: 'aadharCard',
        type: 'file',
        required: false,
      },
      {
        no: 5,
        Label: 'form.gigs.pan',
        name: 'panCard',
        id: 'panCard',
        type: 'file',
        required: false,
      },
      {
        no: 6,
        Label: 'form.gigs.ration',
        name: 'rationCard',
        id: 'rationCard',
        type: 'file',
        required: false,
      },

      {
        no: 7,
        Label: 'form.gigs.other',
        name: 'other',
        id: 'other',
        type: 'file',
        required: false,
      },
    ],
    GIGS_SOCIETY_MODAL: [
      {
        no: 0,
        Label: 'Select Societies',
        name: 'societyId',
        id: 'societyId',
        type: 'mulitpleSelect',

        required: true,
      },
    ],
    EDIT_GIGS_SOCIETY_MODAL: [
      {
        no: 0,
        Label: 'form.gigs.society',
        name: 'societyId',
        id: 'societyId',
        type: 'select',

        required: true,
      },
    ],
    GIGS_SERVICE_MODAL: [
      {
        no: 0,
        Label: 'Select Services',
        name: 'serviceId',
        id: 'serviceId',
        type: 'mulitpleSelect',

        required: false,
      },
    ],
    EDIT_GIGS_SERVICE_MODAL: [
      {
        no: 0,
        Label: 'form.gigs.service',
        name: 'serviceId',
        id: 'serviceId',
        type: 'select',

        required: false,
      },
    ],

    GIGS_SCHEDULE_MODAL: [
      {
        no: 0,
        name: 'time',
        id: 'time',
        type: 'addtime',

        required: true,
      },
    ],
    EDIT_GIGS_SCHEDULE_MODAL: [
      {
        no: 0,
        Label: 'society.start',
        name: 'startTime',
        id: 'startTime',
        type: 'time',

        required: true,
      },
      {
        no: 1,
        Label: 'society.end',
        name: 'endTime',
        id: 'endTime',
        type: 'time',

        required: true,
      },
    ],
    GIGS_INFO_MODAL: [
      {
        no: 0,
        Label: 'form.gigs.msg',
        name: 'message',
        id: 'message',
        type: 'text',
        required: true,
      },
    ],
    BANNER_MODAL: [
      {
        no: 0,
        Label: 'form.banner',
        name: 'banner',
        id: 'banner',
        type: 'file',
        required: true,
      },
    ],
    EDIT_BANNER_MODAL: [
      {
        no: 0,
        Label: 'form.banner',
        name: 'banner',
        id: 'banner',
        type: 'file',
        required: false,
      },
    ],
    RESCHEDULE_MODAL: [
      {
        no: 0,
        Label: 'forms.date',
        name: 'date',
        id: 'date',
        type: 'date',
        required: true,
      },
      {
        no: 1,
        Label: 'society.start',
        name: 'startTime',
        id: 'startTime',
        type: 'time',
        required: true,
      },
      {
        no: 2,
        Label: 'society.end',
        name: 'endTime',
        id: 'endTime',
        type: 'time',
        required: true,
      },
    ],
    FEEDBACK_MODAL: [
      {
        no: 0,
        Label: 'form.user.name',
        name: 'userName',
        id: 'userName',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'form.gigs.name',
        name: 'maidName',
        id: 'maidName',
        type: 'text',
        required: true,
      },
      {
        no: 2,
        Label: 'form.service.name',
        name: 'serviceName',
        id: 'serviceName',
        type: 'text',
        required: true,
      },
      {
        no: 3,
        Label: 'form.feedback.message',
        name: 'message',
        id: 'message',
        type: 'text',
        required: true,
      },
      {
        no: 4,
        Label: 'form.feedback.ratings',
        name: 'ratings',
        id: 'ratings',
        type: 'number',
        required: true,
      },
    ],
    ROLE_AND_ACCESS_MODAL: [
      {
        no: 0,
        Label: 'form.role_and_access.name',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },

      {
        no: 1,
        Label: 'form.role_and_access.password',
        name: 'password',
        id: 'password',
        type: 'password',
        required: true,
      },
      {
        no: 2,
        Label: 'form.role_and_access.email',
        name: 'email',
        id: 'email',
        type: 'email',
        required: true,
      },
      {
        no: 3,
        Label: 'form.role_and_access.mobile',
        name: 'mobile',
        id: 'mobile',
        type: 'number',
        required: true,
      },
    ],
    ATTENDACNE_MODAL: [
      {
        no: 0,
        Label: 'form.attendance.code',
        name: 'securityCode',
        id: 'securityCode',
        type: 'searchSelect',
        required: true,
      },
      {
        no: 1,
        Label: 'form.gigs.society',
        name: 'societyId',
        id: 'societyId',
        type: 'select',

        required: false,
      },
      {
        no: 2,
        Label: 'form.gigs.date',
        name: 'date',
        id: 'date',
        type: 'date',

        required: false,
      },
      {
        no: 3,
        Label: 'form.gigs.startTime',
        name: 'startTime',
        id: 'startTime',
        type: 'time',

        required: false,
      },
      {
        no: 4,
        Label: 'form.gigs.endTime',
        name: 'endTime',
        id: 'endTime',
        type: 'time',

        required: false,
      },
    ],
    SETTING_MODAL: [
      {
        no: 0,
        Label: 'form.service.type',
        name: 'name',
        id: 'name',
        type: 'text',

        required: true,
      },
    ],
    SETTING_CITY_MODAL: [
      {
        no: 0,
        Label: 'form.setting.city',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'form.setting.igst',
        name: 'IGST',
        id: 'IGST',
        type: 'number',
        required: false,
      },
      {
        no: 2,
        Label: 'form.setting.cgst',
        name: 'CGST',
        id: 'CGST',
        type: 'number',
        required: false,
      },

      {
        no: 3,
        Label: 'form.setting.sgst',
        name: 'SGST',
        id: 'SGST',
        type: 'number',
        required: false,
      },
      {
        no: 4,
        Label: 'form.setting.image',
        name: 'image',
        id: 'image',
        type: 'file',
        required: true,
      },
    ],
    EDIT_SETTING_CITY_MODAL: [
      {
        no: 0,
        Label: 'form.setting.city',
        name: 'name',
        id: 'name',
        type: 'text',
        required: true,
      },
      {
        no: 1,
        Label: 'form.setting.igst',
        name: 'IGST',
        id: 'IGST',
        type: 'number',
        required: false,
      },
      {
        no: 2,
        Label: 'form.setting.cgst',
        name: 'CGST',
        id: 'CGST',
        type: 'number',
        required: false,
      },
      {
        no: 3,
        Label: 'form.setting.sgst',
        name: 'SGST',
        id: 'SGST',
        type: 'number',
        required: false,
      },
      {
        no: 4,
        Label: 'form.setting.image',
        name: 'image',
        id: 'image',
        type: 'file',
        required: false,
      },
    ],
    USER_ADDRESS_MODAL: [
      {
        no: 0,
        Label: 'form.user.city',
        name: 'cityId',
        id: 'cityId',
        type: 'select',

        required: false,
      },
      {
        no: 1,
        Label: 'form.user.society',
        name: 'societyId',
        id: 'societyId',
        type: 'select',

        required: false,
      },
      {
        no: 2,
        Label: 'form.user.address',
        name: 'address',
        id: 'address',
        type: 'text',

        required: true,
      },
      {
        no: 3,
        Label: 'form.user.tower',
        name: 'towerId',
        id: 'towerId',
        type: 'select',

        required: false,
      },
      {
        no: 4,
        Label: 'form.user.room',
        name: 'roomId',
        id: 'roomId',
        type: 'select',

        required: false,
      },
      {
        no: 5,
        Label: 'form.user.parking',
        name: 'parkingNumber',
        id: 'parkingNumber',
        type: 'text',

        required: true,
      },
    ],
  },
};

export const CURRANT_USER = {
  user: {},
};

export const DATE_FORMAT = {
  STANDARD_DISPLAY: 'DD-MM-YYYY',
};

export const DISPLAY_TEXT_TYPE = {
  DATE: 'DATE',
  YES_NO: 'YES_NO',
};

export const GENDER = {
  Male: 'M',
  Female: 'F',
  Other: 'O',
};

export const TAB_MENU = {
  USER: [
    {
      no: 0,
      Label: 'user.service',
      name: 'service',
      id: 'service',
      tabId: 'service',
    },
    {
      no: 1,
      Label: 'user.request',
      name: 'request',
      id: 'request',
      tabId: 'request',
    },
    // {
    //   no: 2,
    //   Label: 'user.transaction',
    //   name: 'transaction',
    //   id: 'transaction',
    //   tabId: 'transaction',
    // },
    {
      no: 0,
      Label: 'user.upcoming',
      name: 'upcoming',
      id: 'upcoming',
      tabId: 'upcoming',
    },
    {
      no: 1,
      Label: 'user.ongoing',
      name: 'ongoing',
      id: 'ongoing',
      tabId: 'ongoing',
    },
    {
      no: 3,
      Label: 'user.completed',
      name: 'completed',
      id: 'completed',
      tabId: 'completed',
    },
    {
      no: 4,
      Label: 'user.ticket',
      name: 'ticket',
      id: 'ticket',
      tabId: 'ticket',
    },
    {
      no: 5,
      Label: 'user.address',
      name: 'address',
      id: 'address',
      tabId: 'address',
    },
    {
      no: 6,
      Label: 'user.feedback',
      name: 'feedback',
      id: 'feedback',
      tabId: 'feedback',
    },
  ],
  SOCEITIES: [
    {
      no: 0,
      Label: 'society.tower',
      name: 'tower',
      id: 'tower',
      tabId: 'tower',
    },
    {
      no: 1,
      Label: 'society.flat',
      name: 'flat',
      id: 'flat',
      tabId: 'flat',
    },
    {
      no: 2,
      Label: 'society.service',
      name: 'service',
      id: 'service',
      tabId: 'service',
    },
    {
      no: 3,
      Label: 'society.parking',
      name: 'parking',
      id: 'parking',
      tabId: 'parking',
    },
    {
      no: 4,
      Label: 'society.user',
      name: 'user',
      id: 'user',
      tabId: 'user',
    },
    {
      no: 5,
      Label: 'society.gigs',
      name: 'gigs',
      id: 'gigs',
      tabId: 'gigs',
    },
  ],
  SERVICE: [
    {
      no: 0,
      Label: 'service.variety',
      name: 'variety',
      id: 'variety',
      tabId: 'variety',
    },
    {
      no: 1,
      Label: 'service.details',
      name: 'details',
      id: 'details',
      tabId: 'details',
    },
  ],
  GIGS: [
    // {
    //   no: 0,
    //   Label: 'gigs.transaction',
    //   name: 'transaction',
    //   id: 'transaction',
    //   tabId: 'transaction',
    // },
    {
      no: 1,
      Label: 'gigs.society',
      name: 'society',
      id: 'society',
      tabId: 'society',
    },

    {
      no: 2,
      Label: 'gigs.schedule',
      name: 'schedule',
      id: 'schedule',
      tabId: 'schedule',
    },
    {
      no: 3,
      Label: 'gigs.attendance',
      name: 'attendance',
      id: 'attendance',
      tabId: 'attendance',
    },
    {
      no: 4,
      Label: 'gigs.service',
      name: 'service',
      id: 'service',
      tabId: 'service',
    },
    {
      no: 5,
      Label: 'gigs.info',
      name: 'info',
      id: 'info',
      tabId: 'info',
    },
    {
      no: 0,
      Label: 'gigs.upcoming',
      name: 'upcoming',
      id: 'upcoming',
      tabId: 'upcoming',
    },
    {
      no: 1,
      Label: 'gigs.ongoing',
      name: 'ongoing',
      id: 'ongoing',
      tabId: 'ongoing',
    },
    {
      no: 9,
      Label: 'gigs.order',
      name: 'completed',
      id: 'completed',
      tabId: 'completed',
    },
    {
      no: 6,
      Label: 'gigs.feedback',
      name: 'feedback',
      id: 'feedback',
      tabId: 'feedback',
    },
    {
      no: 7,
      Label: 'gigs.ticket',
      name: 'ticket',
      id: 'ticket',
      tabId: 'ticket',
    },
    {
      no: 8,
      Label: 'gigs.work',
      name: 'work',
      id: 'work',
      tabId: 'work',
    },

  ],
  ATTENDACNE: [
    {
      no: 0,
      Label: 'attendance.list',
      name: 'list',
      id: 'list',
      tabId: 'list',
    },
    {
      no: 1,
      Label: 'attendance.leave',
      name: 'leave',
      id: 'leave',
      tabId: 'leave',
    },
  ],
  USER_REQUEST: [
    {
      no: 0,
      Label: 'request.pending',
      name: 'pending',
      id: 'pending',
      tabId: 'pending',
    },
    {
      no: 1,
      Label: 'request.assign',
      name: 'assign',
      id: 'assign',
      tabId: 'assign',
    },
    {
      no: 2,
      Label: 'request.approve',
      name: 'approve',
      id: 'approve',
      tabId: 'approve',
    },

    {
      no: 3,
      Label: 'request.booking',
      name: 'booking',
      id: 'booking',
      tabId: 'booking',
    },
    {
      no: 4,
      Label: 'request.history',
      name: 'history',
      id: 'history',
      tabId: 'history',
    },
  ],
  ORDERS: [
    {
      no: 0,
      Label: 'order.upcoming',
      name: 'upcoming',
      id: 'upcoming',
      tabId: 'upcoming',
    },
    {
      no: 1,
      Label: 'order.ongoing',
      name: 'ongoing',
      id: 'ongoing',
      tabId: 'ongoing',
    },
    {
      no: 2,
      Label: 'order.completed',
      name: 'completed',
      id: 'completed',
      tabId: 'completed',
    },
  ],
  INVOICE: [
    {
      no: 0,
      Label: 'invoice.receipt.user',
      name: 'userReceipt',
      id: 'userReceipt',
      tabId: 'userReceipt',
    },
    {
      no: 0,
      Label: 'invoice.receipt.gig',
      name: 'gigReceipt',
      id: 'gigReceipt',
      tabId: 'gigReceipt',
    },
    {
      no: 1,
      Label: 'invoice.invoice',
      name: 'invoice',
      id: 'invoice',
      tabId: 'invoice',
    },

  ],
};

export default CONSTANTS;
