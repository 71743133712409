import moment from 'moment';

export function formatAmount(amount) {
    const absAmount = Math.abs(amount);

    if (absAmount >= 1e9) {
        return `${(amount / 1e9)?.toFixed(2)} B `; // Billion
    }
    if (absAmount >= 1e6) {
        return `${(amount / 1e6)?.toFixed(2)} M`; // Million
    }
    if (absAmount >= 1e3) {
        return `${(amount / 1e3)?.toFixed(2)} K`; // Thousand
    }
    return amount.toString(); // Less than a thousand
}

export function sortTimeArray(timeArray) {
    // Convert time strings to moment objects
    const timeRanges = timeArray.map((timeStr) => {
        const [start, end] = timeStr.split(' to ');
        return { start: moment(start, 'hh:mm A'), end: moment(end, 'hh:mm A') };
    });

    // Sort the time ranges
    timeRanges.sort((a, b) => a.start - b.start);

    // Convert sorted time ranges back to strings
    const sortedTimeArray = timeRanges.map(({ start, end }) => {
        return `${start.format('hh:mm A')} to ${end.format('hh:mm A')}`;
    });

    return sortedTimeArray;
}

export const getTimeInfo = (startTime) => {
    const momentTime = moment.utc(startTime, 'HH:mm:ss').local();

    if (momentTime.isSameOrBefore(moment('11:59 AM', 'hh:mm A'))) {
        return 'morning';
    }
    return 'evening';
};

export const getTimeSlots = (bookings) => {
    let slotData = {};
    if (bookings && bookings?.length > 1) {
        const time1 = getTimeInfo(bookings[0]?.startTime);
        const time2 = getTimeInfo(bookings[1]?.startTime);



        if (time1 === 'morning' && time2 === 'evening') {
            // time 1 is morning and time_2 is evening

            slotData = {
                startTime: moment
                    .utc(bookings[0]?.startTime, 'HH:mm:ss')
                    .local()
                    .format('HH:mm:ss'),
                endTime: moment
                    .utc(bookings[0]?.endTime, 'HH:mm:ss')
                    .local()
                    .format('HH:mm:ss'),
                startTime_evening: moment
                    .utc(bookings[1]?.startTime, 'HH:mm:ss')
                    .local()
                    .format('HH:mm:ss'),
                endTime_evening: moment
                    .utc(bookings[1]?.endTime, 'HH:mm:ss')
                    .local()
                    .format('HH:mm:ss'),
            };
        } else {
            // time 1 is evening and time 2 is morning

            slotData = {
                startTime: moment
                    .utc(bookings[1]?.startTime, 'HH:mm:ss')
                    .local()
                    .format('HH:mm:ss'),
                endTime: moment
                    .utc(bookings[1]?.endTime, 'HH:mm:ss')
                    .local()
                    .format('HH:mm:ss'),
                startTime_evening: moment
                    .utc(bookings[0]?.startTime, 'HH:mm:ss')
                    .local()
                    .format('HH:mm:ss'),
                endTime_evening: moment
                    .utc(bookings[0]?.endTime, 'HH:mm:ss')
                    .local()
                    .format('HH:mm:ss'),
            };
        }
    }
    return slotData;
};

// export function filterUniqueRecords(data) {
//     const uniqueRecords = {};
//     // const seenMobiles = new Set();
//     console.log(data);
//     data.forEach((record) => {

//         if (record.mobile in uniqueRecords) {

//             if (record?.gigStatus !== 'reject') {

//                 if (record?.bookingStatus === 'complete' && record?.gigStatus === 'approve') {
//                     uniqueRecords[record?.mobile].count += 1;
//                 }
//                 else if (record?.gigStatus === 'canceledByUser' || record?.gigStatus === 'canceledByAdmin') {
//                     uniqueRecords[record?.mobile].count += 1;
//                 }
//                 else if (record?.gigStatus === 'notAttend' || record?.gigStatus === 'canceled') {
//                     uniqueRecords[record?.mobile].leave += 1;
//                 }
//                 if (record?.subscriptionType === 'day-month') {

//                     uniqueRecords[record?.mobile].totalBookings += 1;
//                 }
//             }
//         }

//         else if (record?.gigStatus !== 'reject') {
//             uniqueRecords[record?.mobile] = {
//                 ...record,
//                 count: record?.bookingStatus === 'complete' && record?.gigStatus === 'approve' ? 1 : 0,
//                 leave: record?.gigStatus === 'notAttend' ? 1 : 0,
//                 // totalBookings: record?.subscriptionType === 'day-month' ? 1 : record?.subscriptionType === 'day' && record?.isSecondSlot ? 2 : record?.subscriptionType === 'day' && !record?.isSecondSlot ? 1 : record?.subscriptionType === 'month' && !record?.isSecondSlot ? 30 : 60
//                 totalBookings: record?.subscriptionType === 'day-month' ? 1 : 0
//             }
//         }

//     });
//     return uniqueRecords;
// }
export function filterUniqueRecords(data) {
    const uniqueRecords = {};

    data.forEach((record) => {
        if (record.mobile in uniqueRecords) {
            if (record.gigStatus !== 'reject') {
                if (
                    record.bookingStatus === 'complete' &&
                    record.gigStatus === 'approve'
                ) {
                    uniqueRecords[record.mobile].count += 1;
                } else if (
                    record.gigStatus === 'canceledByUser' ||
                    record.gigStatus === 'canceledByAdmin'
                ) {
                    uniqueRecords[record.mobile].count += 1;
                } else if (
                    record.gigStatus === 'notAttend' ||
                    record.gigStatus === 'canceled'
                ) {
                    uniqueRecords[record.mobile].leave += 1;
                }
                uniqueRecords[record.mobile].totalBookings += 1;
                if (record.subscriptionType === 'day-month') {
                    uniqueRecords[record.mobile].hasDayMonthBooking = true;
                }
            }
        } else if (record.gigStatus !== 'reject') {
            uniqueRecords[record.mobile] = {
                ...record,
                count:
                    record.bookingStatus === 'complete' && record.gigStatus === 'approve'
                        ? 1
                        : 0,
                leave: record.gigStatus === 'notAttend' ? 1 : 0,
                totalBookings: 1,
                hasDayMonthBooking: record.subscriptionType === 'day-month',
                hasMonthBooking: record.subscriptionType === 'month',
            };
        }
    });

    return uniqueRecords;
}

export function maskAccountNumber(accountNumber, visibleDigits = 5) {
    const lengths = accountNumber?.length;
    const visiblePart = accountNumber?.slice(lengths - visibleDigits);
    const maskedPart = '*'.repeat(lengths - visibleDigits);
    return maskedPart + visiblePart;
}

export const orderStatus = {
    day: 'Daily',
    month: 'Monthly',
    'day-month': 'Replacement',
    replacement: 'Replacement',
};

export const mappedStatus = {
    pending: 'Pending',
    assign: 'Assign',
    approve: 'Approve',
    reject: 'Reject',
    complete: 'Complete',
    canceled: 'Canceled',
    notAttend: 'Not-Attend',
    refunded: 'Refunded',
    reschedule: 'Reschedule',
    success: 'Success',
    canceledByUser: 'Canceled By User',
    canceledByAdmin: 'Canceled By Admin',
    canceledByGig: 'Canceled By Gig',
};
export const ORDER_STATUS = (status, role = null) => {
    if (status === 'canceled') {
        return `Canceled By ${role}`;
    }
    if (status === 'reschedule') {
        return `Reschedule By ${role}`;
    }
    return mappedStatus[status];
};

export const userAddressData = (addressObj) => {
    return addressObj
        ? `${addressObj?.tower?.name}-${addressObj?.room?.name},${addressObj?.society?.name},${addressObj?.city?.name}`
        : '';
};

export function localTimeConvert(utcTimeString, date = new Date()) {
    if (!utcTimeString) {
        return false; // or throw an error, depending on your use case
    }

    const timeComponents = utcTimeString.split(':');
    if (timeComponents.length !== 3) {
        return false; // or throw an error, depending on your use case
    }

    const orderTime = moment().set({
        hour: timeComponents[0],
        minute: timeComponents[1],
        second: timeComponents[2],
    });
    const utcTime = orderTime.add(5, 'h').add(30, 'm');
    const adjustedTime = moment.utc(`${date}T${utcTime.format('HH:mm:ss')}`); // Example date and time

    return adjustedTime?.format('YYYY-MM-DDTHH:mm:ss');
}

export function isTimePassed(utcTimeString, date = new Date()) {
    if (!utcTimeString) {
        return false; // or throw an error, depending on your use case
    }
    // return moment().isAfter(adjustedTime);
    return moment().isAfter(localTimeConvert(utcTimeString, date));
}

export const filterUpcomingOrders = (orders) => {
    return orders?.filter((ele) => !isTimePassed(ele?.startTime, ele?.date));
};
export const filterOngoingOrders = (orders) => {
    return orders?.filter((ele) =>
        ele?.isSecondSlot
            ? !isTimePassed(ele?.secondSlotEndTime, ele?.endDate)
            : !isTimePassed(ele?.endTime, ele?.endDate)
    );
};

export const getActiveGigData = (bookingAssigns) => {
    let gig = {};
    const activeList = bookingAssigns?.filter((ele) => ele?.status === 'approve');
    if (activeList.length > 0) {
        const todaysBooking = activeList?.filter(
            (ele) => ele?.date === moment().format('YYYY-MM-DD')
        );
        if (todaysBooking?.length > 0) {
            gig = { ...todaysBooking[0]?.gig, status: todaysBooking[0]?.status };
        } else {
            gig = { ...activeList[0]?.gig, status: activeList[0]?.status };
        }
    } else if (bookingAssigns.length > 0) {
        gig = { ...bookingAssigns[0]?.gig, status: bookingAssigns[0]?.status };
    }
    return gig;
};

export const convertUTCToLocal = (utcTime, format = 'YYYY-MM-DD HH:mm:ss') => {
    const localTime = moment.utc(utcTime).local().format(format);
    return localTime;
};
export const convertUTCToLocalTime = (utcTime) => {
    const momentObj = moment.utc(utcTime, 'HH:mm:ss');
    return momentObj.local();
};
export const sortDataByTime = (data) => {
    return data.sort((a, b) => {
        const localTimeA = convertUTCToLocalTime(a.startTime);
        const localTimeB = convertUTCToLocalTime(b.startTime);

        // Extract the time only and sort based on that
        const timeA = localTimeA.format('HH:mm:ss');
        const timeB = localTimeB.format('HH:mm:ss');

        return timeA.localeCompare(timeB);
    });
};

export const findLatestTime = (arrayOfObjects, gigId = null) => {
    let latestObject = null;

    // console.log(arrayOfObjects, gigId);

    arrayOfObjects.forEach((obj) => {
        if (!gigId || obj.gigId === gigId) {
            const createdAtMoment = moment(obj.updatedAt);
            if (
                !latestObject ||
                createdAtMoment.isAfter(moment(latestObject.updatedAt))
            ) {
                latestObject = obj;
            }
        }
    });

    return latestObject;
};
